import { Pagination, Select } from "antd";
import { useContext } from "react";
import ProductCard from "../components/productCard";
import { GlobalContext } from "../Auth/globalContext";
import Footer from "../components/footer";
import ThreePromises from "../components/3-promise-footer";
import PreLoader from "../components/loader";
import { scrollToTop } from "../components/functions";
const ProductsPage = () => {
    const { setMobileNavHighlight, products } = useContext(GlobalContext);
    setMobileNavHighlight('catalogue')
    const sortOpts = [
        { value: "popularity", label: "Sort by popularity" },
        { value: "average", label: "Sort by average rating" },
        { value: "newest", label: "Sort by newest" },
        { value: "price-low", label: "Price: Low to High" },
        { value: "price-high", label: "Price: High to Low" },

    ]
    return (
        <>
            {!products ? <PreLoader /> : <><div className="lg:mx-36 md:mx-8 mx-4 my-16">
                <div className="flex sm:flex-row flex-col justify-between items-center">
                    <div className="w-full">
                        <h1 className="text-3xl font-nunito font-black text-[#0d1012]">New Arrivals</h1>
                        <span className="font-nunito font-semibold text-[#989999]">Home {'>'} Products</span>
                    </div>
                    <div className="w-full flex sm:justify-end my-8 sm:my-0">
                        <Select className="lg:w-[50%] w-full" size="large" onChange={scrollToTop} options={sortOpts} defaultValue="popularity"></Select>
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-3 lg:gap-12 gap-8 my-4">
                    {products.map((product, index) => {
                        console.log(`${product.name} => ${product.image}`)
                        return <ProductCard name={product.name} ratings={product.rating} price={product.price} discount={product.discount ? product.discount : "NEW"} url={product.id} image={product.image} key={index} productInfo={product} />
                    })}
                </div>
                <div className="flex items-center justify-center my-12 mb-24">
                    <Pagination defaultCurrent={1} total={50} onChange={scrollToTop} />
                </div>
            </div>
                <ThreePromises /></>}

            <Footer />
        </>
    )
}
export default ProductsPage;