import { Popconfirm, Result, Select } from "antd";
import { useContext, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GlobalContext } from "../Auth/globalContext";
import ThreePromises from "../components/3-promise-footer";
import Footer from "../components/footer";
import { addItemsCountArr } from "../components/functions";
const Cart = () => {
    const { setMobileNavHighlight, messageApi, loginStatus } = useContext(GlobalContext);
    setMobileNavHighlight("cart");
    const TableCard = () => {
        const [itemCount, setItemCount] = useState(1);
        return (
            <tr className="border-b">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    <div className="flex items-center gap-4">
                        <div className="w-16 h-16 rounded-md bg-gray-400"></div>
                        <div className="font-semibold md:text-lg">Nike Air Max 90</div>
                    </div>
                </th>
                <td className="px-6 py-4 text-black">$159.99 <del className="text-gray-500">$45.99</del></td>
                <td className="px-6 py-4">
                    <div className="flex gap-4">
                        <Select onChange={(e) => { setItemCount(e) }} options={addItemsCountArr} defaultValue="1" />
                        <div className="flex flex-col justify-center">
                            <div className="font-semibold">{itemCount}</div>
                        </div>
                    </div>
                </td>
                <td className="px-6 py-4 flex gap-4 items-center">
                    $159.99
                    <Popconfirm title="Are you sure to remove this item?" okText="Remove" cancelText="Nope" onConfirm={() => { messageApi.info("You clicked on *Remove*") }}>
                        <AiOutlineDelete className="text-xl m-4 cursor-pointer" />
                    </Popconfirm>
                </td>
            </tr>
        )
    }
    return (
        <>
            {loginStatus ? (<><div className="lg:mx-12 mx-4 md:mx-8 my-16 font-manrope">
                <div className="flex gap-8 flex-col lg:flex-row">
                    <div className="lg:w-[70%] w-full">
                        <div className="relative overflow-x-auto ">
                            <table className="w-full text-sm text-left">
                                <thead className="text-lg uppercase">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">Product</th>
                                        <th scope="col" class="px-6 py-3">Price</th>
                                        <th scope="col" class="px-6 py-3">Quantity</th>
                                        <th scope="col" class="px-6 py-3">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableCard />
                                    <TableCard />
                                    <TableCard />
                                </tbody>

                            </table>
                        </div>
                        <div className="my-6">
                            <div className="flex w-full gap-6 md:gap-2 lg:gap-0 flex-col md:flex-row justify-between">
                                <div className="flex w-full">
                                    <input className="focus:outline-none w-full border-[1px] border-gray-300 py-3 px-6 rounded-l-md" placeholder="Enter promo code" />
                                    <button className="bg-[#002ce3] text-white px-8 hover:shadow-xl tansition ease-in-out duration-300 rounded-r-md">Apply</button>
                                </div>
                                <div className="w-full flex md:justify-end items-center">
                                    <button className="bg-[#002ce3] text-white px-8 py-3 rounded-lg hover:shadow-xl tansition ease-in-out duration-300">Update Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="w-full">
                            <table className="w-full text-sm text-left">
                                <thead className="text-lg uppercase">
                                    <tr>
                                        <th scope="col" class="px-6 pr-20 py-3">Order Summary</th>
                                        <th scope="col" class="px-6 py-3"></th>
                                    </tr>
                                </thead>
                                <tbody className="md:text-lg">
                                    <tr className="border-b">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            <div className="flex gap-4">
                                                <div className="font-semibold text-gray-700">SubTotal</div>
                                            </div>
                                        </th>
                                        <td className="px-6 text-end py-4 text-gray-600">$159.99</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            <div className="flex gap-4">
                                                <div className="font-semibold text-gray-700">Discount (5%)</div>
                                            </div>
                                        </th>
                                        <td className="px-6 text-end py-4 text-red-600">-$6.7</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            <div className="flex gap-4">
                                                <div className="font-semibold text-gray-700">Shipping</div>
                                            </div>
                                        </th>
                                        <td className="px-6 text-end py-4 text-gray-600">$10</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                            <div className="flex gap-4">
                                                <div className="font-bold text-gray-700">Grand Total</div>
                                            </div>
                                        </th>
                                        <td className="px-6 text-end py-4 text-gray-600 font-semibold">$163.29</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className="my-8 w-full bg-[#002ce3] text-white py-3 rounded-lg hover:shadow-xl tansition ease-in-out duration-300">Proceed to Checkout</button>
                        </div>
                    </div>
                </div>
            </div>
                <ThreePromises /></>) : (<><Result status="403"
                    title="Oh no!"
                    subTitle="Please login/signup to view your cart."
                    extra= {<Link to="/auth/login"><button className="bg-[#eb6127] text-white px-8 py-2 rounded-md transition ease-in-out duration-100 hover:shadow-xl">Login</button></Link>}
                    /></>)}
            <Footer />
        </>
    )
}
export default Cart;