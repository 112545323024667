import { useContext, useEffect } from "react";
import { GlobalContext } from "../Auth/globalContext";
import { useNavigate } from "react-router";
const Favorites = () => {
    const { loginStatus, setLoginStatus, email, setEmail, authToken, setAuthToken, setMobileNavHighlight } = useContext(GlobalContext);
    const navigate = useNavigate();
    setMobileNavHighlight('auth');
    useEffect(() => {
        if (!loginStatus) {
            if (localStorage.auth) {
                var ld = JSON.parse(localStorage.auth);
                setEmail(ld.email);
                setAuthToken(ld.authKey);
                setLoginStatus(true);
            } else {
                navigate('/auth/login');
            }
        }
    }, [loginStatus])
    return (
        <>
            My fav
        </>
    )
}
export default Favorites;