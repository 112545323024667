export function calculateOriginalPrice(discountedPrice, discountPercent) {
    discountPercent = discountPercent / 100;
    return parseFloat(discountedPrice / (1 - discountPercent)).toFixed(2);
}

export function token() {
    var rand = function () {
        return Math.random().toString(36).substr(2);
    };
    return rand() + rand();
}
export const addItemsCountArr = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];
export function scrollToTop(){
    window.scrollTo(0,0);
}