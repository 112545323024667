import { HiArrowLongRight } from "react-icons/hi2";
import heroSVG from "../assets/img/hero.svg"
import ProductCard from "../components/productCard";
import Shoe1 from "../assets/img/shoe-1.png";
import SponsorsCard from "../components/sponsors";
import Footer from "../components/footer";
import { GlobalContext } from "../Auth/globalContext";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import PreLoader from "../components/loader";
const Landing = () => {
    const { mobileNavHighlight, setMobileNavHighlight, products } = useContext(GlobalContext);
    useEffect(() => {
        setMobileNavHighlight('home');
    }, [mobileNavHighlight])
    return (
        <>
            {!products ? <PreLoader /> : (<>

                <div>
                    <div className="grid md:grid-cols-2 ">
                        <div className="md:mx-12 mx-6 my-12 md:pt-16 pt-6">
                            <div className="flex gap-5 items-center text-[#eb6228]">
                                <h1 className="text-xl font-nunito">New Arrival</h1>
                                <div className="h-[2px] w-28 mt-1 bg-[#eb6228]"></div>
                            </div>
                            <div className="lg:text-6xl md:text-5xl text-4xl font-bold pt-4 font-montserrat text-[#1d1f21]">Crafting From Our Hand To You</div>
                            <div className="my-8 md:text-lg text-[#1d1f21] font-nunito">Cadeo makes it easy to send the perfect gift. <br /> All you need is a phone number or email address.</div>
                            <div>
                                <Link to="/products"><button className="flex font-montserrat gap-4 bg-[#eb6228] text-white rounded-xl p-4 items-center text-xl font-semibold">Explore <HiArrowLongRight className="text-2xl mt-1" /></button></Link>
                            </div>
                        </div>
                        <div>
                            <div className="md:flex hidden items-center mt-4 justify-center h-full"><img className=" rotate-6" height={"40%"} width={"40%"} src={heroSVG} /></div>
                        </div>
                    </div>
                    <div className="lg:m-16 m-4 lg:mx-24 flex flex-col gap-8">
                        <div>
                            <div className="uppercase font-bold text-2xl font-manrope ">Hits of the Season</div>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                                {products.slice(0, 4).map((product, index) => {
                                    return (
                                        <ProductCard url={product.id} image={product.image} name={product.name} price={product.price} discount={product.discount ? product.discount : 'NEW'} key={index} />
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <div className="grid lg:grid-cols-2 lg:gap-4 gap-12 my-12">
                                {products.slice(10, 11).map((product, index) => {
                                    return (
                                        <ProductCard url="true" name="Shoes" image={product.image} price="Upto 20% discount on shoes" />
                                    )
                                })}
                                {products.slice(14, 15).map((product, index) => {
                                    return (
                                        <ProductCard url="true" name="Puma" image={product.image} price="New Arrival" />
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            <div className="uppercase font-bold text-2xl font-manrope">New Arrivals</div>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                                {products.slice(5, 9).map((product, index) => {
                                    return (
                                        <ProductCard url={product.id} image={product.image} name={product.name} price={product.price} discount={product.discount ? product.discount : 'NEW'} key={index} />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="md:my-8 mb-8">
                            <SponsorsCard />
                        </div>
                        <div className="my-12 font-manrope">
                            <div className="px-12 py-8 bg-[#1d1f21] rounded-2xl">
                                <div className="flex flex-col md:flex-row md:gap-12">
                                    <div className="lg:mr-24 flex items-center justify-center">
                                        <div className="h-full flex items-center justify-center flex-col ">
                                            <div className="uppercase text-white font-black  font-montserrat text-2xl md:text-4xl">How to choose the right size?</div>
                                            <div className="flex gap-4 mt-8 w-full items-center">
                                                <button className="border-2 border-[#d4611f] p-3 md:px-8 px-4 text-white rounded-xl font-semibold">Snowboard</button>
                                                <button className="border-2 border-[#d4611f] p-3 md:px-8 px-4 text-white rounded-xl font-semibold">Skateboard</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full hidden md:flex justify-center">
                                        <img src={Shoe1} alt="" width={"50%"} height={"50%"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
            <Footer />
        </>
    )
}
export default Landing;