import { BiGridAlt, BiHomeAlt } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { AiOutlineCustomerService, AiOutlineFileText, AiOutlineHeart, AiOutlineHome, AiOutlineLogout, AiOutlineShareAlt, AiOutlineShoppingCart, AiOutlineSmile, AiOutlineTeam, AiOutlineTrophy, AiOutlineUser } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { TfiMenu, TfiClose } from "react-icons/tfi"
import { useContext, useEffect, useState } from "react";
import { Drawer, Popconfirm, Modal } from "antd";
import { RiShoppingBag3Line } from "react-icons/ri"
import { Link } from "react-router-dom";
import { GlobalContext } from "../Auth/globalContext";
const Navbar = () => {
    const [navHamVisibility, setNavHamVisibility] = useState(true);
    const { loginStatus, setLoginStatus, email, setEmail, messageApi, authToken, setAuthToken, mobileNavHighlight, setMobileNavHighlight } = useContext(GlobalContext);
    const [mobileDrawerVisibility, setMobileDrawerVisibility] = useState(false);
    const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);
    const showDrawer = () => {
        setMobileDrawerVisibility(true);
    }
    const mobileDrawerOnClose = () => {
        setMobileDrawerVisibility(false);
        setNavHamVisibility(true);
    }
    const Logo = () => {
        return <Link to="/">SoleMate&nbsp;<span className="text-[#eb6228]">Shoes</span></Link>
    }
    //auto-login
    useEffect(() => {
        if (!loginStatus) {
            if (localStorage.auth) {
                var ld = JSON.parse(localStorage.auth);
                setEmail(ld.email);
                setAuthToken(ld.authKey);
                setLoginStatus(true);
            }
        }
    }, [loginStatus])
    function logout() {
        if (localStorage.auth) {
            messageApi.loading("Hold on...", 3);
            setTimeout(() => {
                delete localStorage.auth;
                setLoginStatus(false);
                messageApi.success("You're logged out!");
            }, 3000);
        }
    }
    useEffect(() => {
        if (mobileNavHighlight) {
            document.querySelectorAll('[m-nav-elm]').forEach((elm, index) => {
                elm.classList.remove("nav-active");
            })
            document.querySelectorAll(`[id="${mobileNavHighlight}"]`).forEach((elm, index) => {
                elm.classList.add('nav-active')
            });
        }
    }, [mobileNavHighlight]);
    const LogoutParent = (props) => {
        return (
            <Popconfirm
                title="Are you sure you want to logout!?"
                description=""
                onConfirm={logout}
                onCancel={() => { }}
                okText="Yes"
                cancelText="No"
            >{props.children}</Popconfirm>
        )
    }
    return (
        <>
            <div className="font-nunito">
                <div className="md:flex hidden gap-5 justify-between mx-12 mt-4  items-center">
                    <div className="text-[#0e1012] flex gap-1 items-center ">
                        <GrLocation />
                        India
                        <a href="tel:+919176097404"><div className="text-gray-400">+91 (917) 609-7404</div></a>
                    </div>
                    <div className="flex gap-8 font-semibold text-sm">
                        {loginStatus ? <></> : <a href="#" className="text-[#eb6228]">Home</a>}
                        <a href="#">Blog</a>
                        <a href="#">About Us</a>
                        <a href="#">Contact</a>
                        {loginStatus ? <LogoutParent><a href="javascript:;" className="">Logout</a></LogoutParent> : <></>}
                    </div>
                </div>
                <div className="flex gap-5 justify-between mt-4 lg:mx-12 mx-4 md:mx-8 items-center h-full">
                    <div className="flex gap-3 items-center md:flex-row flex-col w-full">
                        <div className="font-bold md:text-2xl text-3xl logo uppercase text-[#000205]">
                            <Logo />
                        </div>
                        <Link to="/products"><button className="lg:flex hidden ml-4 items-center gap-2 bg-[#000205] text-white font-semibold p-2 rounded-xl px-4"><BiGridAlt />Catalogue</button></Link>
                        <div className="flex w-full items-center">
                            <div className="w-full items-center flex rounded-lg bg-[#f2f2f2] text-gray-600">
                                <input className="focus:outline-none pl-4 lg:pr-96 w-full bg-transparent" placeholder="Product, brand or color" />
                                <div className="mr-1 h-full flex items-center p-3 cursor-pointer"><FiSearch /></div>
                            </div>
                            <div className="lg:hidden flex ml-4 py-2 rounded-xl cursor-pointer items-center h-full" onClick={() => { setNavHamVisibility(!navHamVisibility); showDrawer(); }}>
                                {navHamVisibility ? <TfiMenu className="text-2xl" /> : <TfiClose className="text-2xl" />}
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:flex items-center gap-8">
                        <Link to={loginStatus ? '/account/profile' : '/auth/login'}>
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <AiOutlineUser className="text-2xl" />
                                <p className="font-semibold text-gray-500">{loginStatus ? 'Account' : 'Login'}</p>
                            </div>
                        </Link>
                        <Link to="/account/favorites">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <AiOutlineHeart className="text-2xl" />
                                <p className="font-semibold text-gray-500">Favorites</p>
                            </div>
                        </Link>
                        <Link to="/cart">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <AiOutlineShoppingCart className="text-2xl" />
                                <p className="font-semibold text-gray-500">Cart</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div >
            <Drawer title={<><div className="text-xl logo uppercase text-[#000205]"><Logo /></div></>} placement="right" onClose={mobileDrawerOnClose} open={mobileDrawerVisibility}>
                <div className="flex flex-col" onClick={mobileDrawerOnClose}>
                    <Link to={loginStatus ? '/account/profile' : '/auth/login'}>
                        <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                            <AiOutlineUser /> {loginStatus ? 'My Account' : 'Login'}
                        </div>
                    </Link>
                    <Link to="/account/favorites">
                        <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                            <AiOutlineHeart /> Favorites
                        </div>
                    </Link>
                    <Link to="/cart">
                        <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                            <AiOutlineShoppingCart /> Cart
                        </div>
                    </Link>

                    <div className="h-[1px] bg-slate-200" />
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineFileText /> Blog
                    </div>
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineTeam /> About us
                    </div>
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineTrophy /> Gift cards
                    </div>

                    <div className="h-[1px] bg-slate-200" />
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineShareAlt /> Refer & win 🎉
                    </div>
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineSmile /> Feedback
                    </div>
                    <div className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                        <AiOutlineCustomerService /> Help & support
                    </div>

                    {loginStatus === true ? (<>
                        <div className="h-[1px] bg-slate-200" />
                        <LogoutParent><div onClick={() => { setLogoutModalVisibility(true) }} className="flex gap-8 items-center text-lg py-4 cursor-pointer hover:bg-[#f1f1f1]">
                            <AiOutlineLogout /> Logout
                        </div>
                        </LogoutParent>
                    </>) : <></>}

                </div>
            </Drawer>
            {/* Mobile bottom nav */}
            <div className="md:hidden block">
                <div className="fixed bottom-0 w-full h-16 flex justify-between bg-white shadow-2xl drop-shadow-2xl">
                    <div className="flex justify-between items-center py-2 px-4 w-full">
                        <div className="flex gap-4 items-center justify-around w-full">
                            <Link to="/" className="" id="home" m-nav-elm="">
                                <div className="flex flex-col gap-1 items-center justify-center">
                                    <BiHomeAlt className="text-3xl" />
                                </div>
                            </Link>
                            <Link to="/products" id="catalogue" m-nav-elm="">
                                <div className="flex flex-col gap-1 items-center justify-center">
                                    <BiGridAlt className="text-3xl" />
                                </div>
                            </Link>
                            <Link to="/cart" id="cart" m-nav-elm="">
                                <div className="flex flex-col gap-1 items-center justify-center">
                                    <RiShoppingBag3Line className="text-3xl" />
                                </div>
                            </Link>
                            <a href="#" id="favorites" m-nav-elm="">
                                <div className="flex flex-col gap-1 items-center justify-center">
                                    <AiOutlineHeart className="text-3xl" />
                                </div>
                            </a>
                            <Link id="auth" to={loginStatus ? '/account/profile' : '/auth/login'} m-nav-elm="">
                                <div className="flex flex-col gap-1 items-center justify-center">
                                    <AiOutlineUser className="text-3xl" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Are you sure you want to logout!?"
                open={logoutModalVisibility}
                onOk={() => { setLogoutModalVisibility(false); logout(); }}
                onCancel={() => { setLogoutModalVisibility(false) }}
                okText="Yes"
            ></Modal>
        </>
    )
}
export default Navbar;