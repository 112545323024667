import { CiDeliveryTruck } from "react-icons/ci"
import { GiReceiveMoney } from "react-icons/gi"
import { BsShieldLock } from "react-icons/bs"
export default function ThreePromises() {
    return (
        <>
            <div className="bg-[#f6f7f9] font-manrope">
                <div className="lg:mx-36 md:mx-8 mx-4 mt-16">
                    <div className="grid py-12 gap-12 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                        <div className="flex gap-4">
                            <div className="text-6xl">
                                <CiDeliveryTruck />
                            </div>
                            <div className="flex flex-col gap-3">
                                <div className="text-2xl font-semibold">
                                    Free Delivery
                                </div>
                                <div className="text-sm text-gray-500 font-semibold">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et dolor id lectus faucibus ullamcorpe
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-4">
                            <div className="text-6xl">
                                <GiReceiveMoney />
                            </div>
                            <div className="flex flex-col gap-3">
                                <div className="text-2xl font-semibold">
                                    30 Days Return
                                </div>
                                <div className="text-sm text-gray-500 font-semibold">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et dolor id lectus faucibus ullamcorpe
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-6xl">
                                <BsShieldLock />
                            </div>
                            <div className="flex flex-col gap-3">
                                <div className="text-2xl font-semibold">
                                    2-Years Warranty
                                </div>
                                <div className="text-sm text-gray-500 font-semibold">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et dolor id lectus faucibus ullamcorpe
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}