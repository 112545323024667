import Marquee from "react-fast-marquee";
import s1 from "../assets/img/sponsors/1.svg";
import s2 from "../assets/img/sponsors/2.svg";
import s3 from "../assets/img/sponsors/3.svg";
import s4 from "../assets/img/sponsors/4.svg";
import s5 from "../assets/img/sponsors/5.svg";
import s6 from "../assets/img/sponsors/6.png";
import s7 from "../assets/img/sponsors/7.svg";
import s8 from "../assets/img/sponsors/8.svg";
const SponsorsCard = () => {
    return (
        <>
            <Marquee pauseOnHover={true} className="mt-8">
                <div className="flex">
                    <img src={s1} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s2} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s3} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s4} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s5} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s6} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s7} alt="Sponsers" className="w-24 mx-12" />
                    <img src={s8} alt="Sponsers" className="w-24 mx-12" />
                </div>
            </Marquee>
        </>
    )
}
export default SponsorsCard;