import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const PreLoader = () => {
    return (
        <>
            <div className="h-screen flex items-center justify-center">
                <div className="pb-36 flex justify-center gap-3 items-center text-center">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: "#eb6228" }} spin />} />
                </div>
            </div>
        </>
    )
}
export default PreLoader;