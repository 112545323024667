import { FiTwitter, FiInstagram, FiYoutube, FiFacebook } from "react-icons/fi";
const Footer = () => {
    return (
        <>
            <div className="bg-[#1d1f21] font-nunito">
                <div className="container mx-auto px-4 py-8">
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="flex flex-col gap-4 lg:mt-0 mt-8">
                            <div className="uppercase font-bold text-2xl text-white">Catalogue</div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Actions </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Brands </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">The Shops </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Personal Area </a></div>
                        </div>
                        <div className="flex flex-col gap-4 lg:mt-0 mt-8">
                            <div className="uppercase font-bold text-2xl text-white">Help</div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Shipping and Payment </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Exchange and return </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Questions and answers </a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Gift cards </a></div>
                        </div>
                        <div className="flex flex-col gap-4 lg:mt-0 mt-8 md:mt-12">
                            <div className="uppercase font-bold text-2xl text-white">Our company</div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Blog</a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Contact Us</a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Donate</a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Privacy Policy</a></div>
                            <div className="text-white"><a href="#" className="hover:underline underline-offset-2">Terms of use</a></div>
                        </div>
                        <div className="flex flex-col gap-4 lg:mt-0 mt-8 md:mt-12">
                            <div className="uppercase font-bold text-xl text-white">Socials</div>
                            <div className="text-white flex gap-4">
                                <a href="#" rel="noreferrer noopener" target={'_blank'}><div className="p-4 rounded-xl bg-[#303638]"><FiFacebook /></div></a>
                                <a href="#" rel="noreferrer noopener" target={'_blank'}><div className="p-4 rounded-xl bg-[#303638]"><FiTwitter /></div></a>
                                <a href="#" rel="noreferrer noopener" target={'_blank'}><div className="p-4 rounded-xl bg-[#303638]"><FiInstagram /></div></a>
                                <a href="#" rel="noreferrer noopener" target={'_blank'}><div className="p-4 rounded-xl bg-[#303638]"><FiYoutube /></div></a>
                            </div>
                            <div className="text-white flex flex-col gap-4">
                                <h1 className="font-semibold">Subscribe our newsletter for latest updates</h1>
                                <form>
                                    <input type={'email'} name="email" className="w-full bg-[#f2f2f2] py-2 px-4 text-gray-800 focus:outline-none rounded-xl" placeholder="Enter your email address" />
                                </form>
                                <div className="text-gray-400 mt-6">© {new Date().getFullYear()} Ammonia Sniffers</div>
                            </div>
                        </div>

                        <div>&nbsp;</div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Footer;