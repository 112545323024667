import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Auth from './pages/auth';
import { GlobalContextProvider } from './Auth/globalContext';
import Landing from './pages/landing';
import Navbar from './components/navbar';
import Favorites from './pages/favorites';
import ProductsPage from './pages/products';
import Cart from './pages/cart';
import ProductPreview from './pages/product-preview';
import { useEffect } from 'react';
import { scrollToTop } from './components/functions';
function App() {
  function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
      scrollToTop();
    }, [location])
    return null;
  }
  return (
    <>
      <BrowserRouter>
        <GlobalContextProvider>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/auth/login' element={<Auth view="login" />} />
            <Route path='/auth/signup' element={<Auth view="signup" />} />
            <Route path='/account/favorites' element={<Favorites />} />
            <Route path='/account/profile' element={<Favorites />} />
            <Route path='/products' element={<ProductsPage />} />
            <Route path='/cart' element={<Cart />} />
            <Route path="/product/view/:id?" element={<ProductPreview />} />
          </Routes>
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
