import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import { GlobalContext } from "../Auth/globalContext";
import { useContext, useEffect } from "react";
import { token } from "../components/functions";

const Auth = (props) => {
    const { loginStatus, setLoginStatus, messageApi, setAuthToken, setEmail, setMobileNavHighlight } = useContext(GlobalContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (loginStatus) {
            navigate('/');
        } else {
            setMobileNavHighlight('auth');
        }
    }, [loginStatus]);
    const handleLoginAction = (e) => {
        e.preventDefault();
        var fd = new FormData(e.target);
        var email = fd.get("email"),
            pass = fd.get("password");
        if (email === "user@webxspark.com" && pass === "pass@123") {
            messageApi.loading("Authenticating", 3);
            setTimeout(() => {
                messageApi.success("You're logged in!");
                const ld = {
                    email: email,
                    authKey: token()
                };
                setEmail(email);
                setAuthToken(ld.authKey);
                localStorage.auth = JSON.stringify(ld);
                setLoginStatus(true);
            }, 3000)
        } else {
            messageApi.error("Please enter valid login details!")
        }
    }
    const handleSignUpAction = (e) => {
        e.preventDefault();
        var fd = new FormData(e.target);
        var email = fd.get("email"),
            pass = fd.get("pass"),
            cpass = fd.get('cpass');
        if (email == "" || pass == "" || cpass == "") {
            messageApi.info("Please fill all the fields to continue!");
            return;
        }
        if (pass != cpass) {
            messageApi.error("Two passwords don't match!");
            return;
        }
        messageApi.loading("Please wait", 3);
        setTimeout(() => {
            messageApi.success("You're logged in!");
            const ld = {
                email: email,
                authKey: token()
            };
            setEmail(email);
            setAuthToken(ld.authKey);
            localStorage.auth = JSON.stringify(ld);
            setLoginStatus(true);
        }, 3000)
    }
    return (
        <>
            <div className="flex items-center justify-center py-24">
                <div className="bg-white drop-shadow-2xl rounded-lg lg:w-[40%] mx-4 py-8 px-12">
                    <div className="font-nunito text-center mb-8">
                        <h1 className="font-black text-2xl">{props.view == "login" ? 'Login' : 'Signup'}</h1>
                        <p className="text-gray-500">{props.view == "login" ? 'Please login using account details below.' : <>Welcome to <b>Solemate Shoes</b></>}</p>
                    </div>
                    {
                        props.view == "login" ? (<>
                            <form className="" onSubmit={handleLoginAction}>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <input name="email" value={"user@webxspark.com"} placeholder="Email address" type="email" className="w-full focus:outline-none focus:border-[#eb6127] focus:border-[1.5px] border-[1px] border-[#ca9d89] py-3 px-2 rounded-md" />
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <input name="password" placeholder="Password" value={"pass@123"} type="password" className="w-full focus:outline-none focus:border-[#eb6127] focus:border-[1.5px] border-[1px] border-[#ca9d89] py-3 px-2 rounded-md" />
                                    </div>
                                    <div className="mt-2 text-gray-600">
                                        Forgot your password?
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <button className="bg-[#eb6127] text-white w-full py-3 font-semibold rounded-md">Sign In</button>
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <p>Don't have an account? <Link to="/auth/signup" className="underline underline-offset-2">Sign Up</Link></p>
                                    </div>
                                </div>
                            </form>
                        </>) : (<>
                            <form className="" onSubmit={handleSignUpAction}>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <input name="email" placeholder="Email address" type="email" className="w-full focus:outline-none focus:border-[#eb6127] focus:border-[1.5px] border-[1px] border-[#ca9d89] py-3 px-2 rounded-md" />
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <input name="pass" placeholder="Create a new password" type="password" className="w-full focus:outline-none focus:border-[#eb6127] focus:border-[1.5px] border-[1px] border-[#ca9d89] py-3 px-2 rounded-md" />
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <input name="cpass" placeholder="verify your new password" type="password" className="w-full focus:outline-none focus:border-[#eb6127] focus:border-[1.5px] border-[1px] border-[#ca9d89] py-3 px-2 rounded-md" />
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <button className="bg-[#eb6127] text-white w-full py-3 font-semibold rounded-md">Sign Up</button>
                                    </div>
                                </div>
                                <div className="w-full my-4">
                                    <div className="w-full">
                                        <p>Already an user? <Link to="/auth/login" className="underline underline-offset-2">Login</Link></p>
                                    </div>
                                </div>
                            </form>
                        </>)
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Auth;