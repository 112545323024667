import { createContext, useEffect, useState } from "react"
import { message } from "antd";
export const GlobalContext = createContext()

export const GlobalContextProvider = ({ children }) => {
    const [loginStatus, setLoginStatus] = useState(false);
    const [messageApi, messageContextHolder] = message.useMessage();
    const [email, setEmail] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [mobileNavHighlight, setMobileNavHighlight] = useState(false);
    const [products, setProducts] = useState(false);
    useEffect(() => {
        if (!products) {
            if (localStorage.getItem("products")) {
                setProducts(JSON.parse(localStorage.getItem("products")));
            }
            else {
                fetch("https://solemateshoes.webxspark.com/storage/sample/products.json")
                    .then(res => res.json())
                    .then(data => {
                        setProducts(data.products);
                        localStorage.setItem("products", JSON.stringify(data.products));
                    })
                    .catch(err => {
                        messageApi.error("Something went wrong while fetching products");
                    });
            }

        }
    }, [products]);
    return (
        <GlobalContext.Provider value={{ products, setProducts, mobileNavHighlight, setMobileNavHighlight, loginStatus, setLoginStatus, messageApi, email, setEmail, authToken, setAuthToken }}>
            {children}
            {messageContextHolder}
        </GlobalContext.Provider>
    )
}