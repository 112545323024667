import { useState } from "react";
import { AiFillHeart, AiFillStar, AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { calculateOriginalPrice } from "./functions";

const ProductCard = (props) => {
    const [heartFilled, setHeartFilled] = useState(false);
    const [hoverOverlayVisibility, setHoverOverlayVisibility] = useState(false);
    function overlayVisible() {
        setHoverOverlayVisibility(true);
    }
    function overlayHide() {
        setHoverOverlayVisibility(false);
    }
    function overlayToggle() {
        setHoverOverlayVisibility(!hoverOverlayVisibility);
    }
    return (
        <>
            <div onMouseEnter={overlayVisible} onMouseLeave={overlayHide} >
                <div className="md:h-96 h-72 bg-cover bg-center rounded-lg bg-gray-400" style={{ backgroundImage: `url(${props.image ? props.image : 'https://via.placeholder.com/700x1000.png?text=Shoe+Image'})` }}>
                    {props.productInfo && hoverOverlayVisibility ?
                        <div onClick={() => { setHeartFilled(!heartFilled) }} className="text-[#eb6228] m-4 p-2 absolute lg:text-3xl text-2xl rounded-full cursor-pointer hover:bg-[#f1f1f12c]">
                            {heartFilled ? <AiFillHeart /> : <AiOutlineHeart />}
                        </div>
                        : <></>
                    }


                    {props.discount && parseInt(props.discount) ?
                        <div className="flex items-end h-full justify-end mr-3 pb-3">
                            <span className="bg-[#eb6127] text-white flex items-center font-semibold p-1 px-4 rounded-full">-{props.discount}%</span>
                        </div> :
                        <>{props.discount && !parseInt(props.discount) ? <>
                            <div className="flex items-end h-full justify-end mr-3 pb-3">
                                <span className="bg-[#002ce3] text-white flex items-center font-semibold p-1 px-4 rounded-full">{props.discount}</span>
                            </div>
                        </> :
                            <></>}
                        </>}
                </div>
                <div>
                    <div className="flex gap-4 items-center mb-1 font-manrope">
                        {props.price ? <div className={`font-bold ${parseInt(props.price) ? '' : 'text-gray-500'}`}>{parseInt(props.price) ? '$' : <></>}{props.price}</div> : <></>}
                        {props.discount && parseInt(props.discount) ? <div className="text-gray-500 text-sm"><del>${calculateOriginalPrice(props.price, props.discount)}</del></div> : <></>}
                    </div>
                    {props.url ? (<>
                        <Link to={props.url != "true" ? `/product/view/${props.url}`: '/product/view/'}>
                            <div className={`font-semibold font-manrope ${parseInt(props.price) ? 'text-gray-500' : 'font-semibold text-lg'} text-sm`}>{props.name}</div>
                        </Link>
                    </>) : (<>
                        <div className={`font-semibold font-manrope ${parseInt(props.price) ? 'text-gray-500' : 'font-semibold text-lg'} text-sm`}>{props.name}</div>
                    </>)}
                    {props.ratings ? <div className="text-sm font-semibold md:text-base flex items-center gap-1"><AiFillStar className="text-[#eb6228]" />{props.ratings}</div> : <></>}
                </div>
            </div>
        </>
    )
}
export default ProductCard;