import { Button, Result, Select, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiFillHeart, AiFillStar, AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { GlobalContext } from "../Auth/globalContext";
import ThreePromises from "../components/3-promise-footer";
import Footer from "../components/footer";
import { addItemsCountArr } from "../components/functions";
import ProductCard from "../components/productCard";
import { Link, useNavigate, useParams } from 'react-router-dom';
import PreLoader from "../components/loader";

const ProductPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { setMobileNavHighlight, messageApi, loginStatus, products } = useContext(GlobalContext);
    const [activeImg, setActiveImg] = useState("https://via.placeholder.com/700x500.png?text=Shoe+Image");
    const [selectedSize, setSelectedSize] = useState("M");
    const [selectedColor, setSelectedColor] = useState("black");
    const [isFavorite, setIsFavorite] = useState(false);
    const swapImg = (text = "Preview") => {
        setActiveImg(`https://via.placeholder.com/700x500.png?text=${text}`);
        return true;
    }
    useEffect(() => {
        if (selectedSize) {
            document.querySelectorAll("[wxp-selection]").forEach((elm, index) => {
                elm.classList.remove("selection-active");
            })
            document.querySelectorAll(`[id="${selectedSize}"]`).forEach((elm, index) => {
                elm.classList.add("selection-active");
            })
        }
    }, [selectedSize]);
    useEffect(() => {
        if (selectedColor) {
            document.querySelectorAll("[wxp-selection-color]").forEach((elm, index) => {
                elm.classList.remove("outline-double");
            })
            document.querySelectorAll(`[id="${selectedColor}"]`).forEach((elm, index) => {
                elm.classList.add("outline-double");
            })
        }
    }, [selectedColor]);

    setMobileNavHighlight("catalogue")
    if (!id) {
        navigate('/products');
        return (<>
            {/* no product id provided */}
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary"><Link to="/">Back Home</Link></Button>}
            />
        </>)
    }
    return (
        <>
            {!products ? <PreLoader /> : <>
                <div className="lg:m-12 m-4 my-12 md:m-8">
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="flex items-center justify-center flex-col">
                            <div>
                                <img src={activeImg} className="rounded-lg" alt="shoe" />
                            </div>
                            <div className="mt-4 flex gap-4 justify-between">
                                <div onClick={() => { swapImg("Preview - 1") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                                <div onClick={() => { swapImg("Preview - 2") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                                <div onClick={() => { swapImg("Preview - 3") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                                <div onClick={() => { swapImg("Preview - 4") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                                <div onClick={() => { swapImg("Preview - 5") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                                <div onClick={() => { swapImg("Preview - 6") }}>
                                    <img src="https://via.placeholder.com/100x100.png?text=Preview" className="rounded-lg" alt="shoe" />
                                </div>
                            </div>
                        </div>
                        <div className="font-manrope">
                            <div className="flex flex-col gap-4 w-full">
                                <div className="md:text-2xl lg:text-3xl text-xl font-bold text-[#1d1f21]">Adidas Ultra Boost</div>
                                <div className="flex gap-4 items-center">
                                    <h1 className="text-xl font-semibold">$159.99</h1>
                                    <del className="text-gray-500 text-sm">$199.99</del>
                                    <div className="text-[#eb6127] font-semibold">-20%</div>
                                </div>
                                <div className="text-xl flex gap-1 items-center">
                                    <AiFillStar className="text-[#eb6127]" />
                                    <p className="font-semibold text-lg">4.8</p>
                                </div>
                                <div className="text-gray-700 font-medium">The Adidas Ultra Boost is the ultimate running shoe, designed to provide unmatched comfort and support with every step.</div>
                                <div className="flex flex-col gap-3 mt-3">
                                    <h1 className="text-lg font-bold text-[#1d1f21]">Choose a size</h1>
                                    <div className="flex gap-3">
                                        <button wxp-selection="true" onClick={(e) => { setSelectedSize(e.target.getAttribute('id')) }} id="XS" className="p-3 bg-[#f1f1f1] hover:bg-[#002ce3] ease-in-out duration-100 hover:text-white font-semibold rounded-lg text-sm">XS</button>
                                        <button wxp-selection="true" onClick={(e) => { setSelectedSize(e.target.getAttribute('id')) }} id="S" className="p-3 px-4 bg-[#f1f1f1] hover:bg-[#002ce3] ease-in-out duration-100 hover:text-white font-semibold rounded-lg text-sm">S</button>
                                        <button wxp-selection="true" onClick={(e) => { setSelectedSize(e.target.getAttribute('id')) }} id="M" className="p-3 px-4 bg-[#f1f1f1] hover:bg-[#002ce3] ease-in-out duration-100 hover:text-white font-semibold rounded-lg text-sm">M</button>
                                        <button wxp-selection="true" onClick={(e) => { setSelectedSize(e.target.getAttribute('id')) }} id="L" className="p-3 px-4 bg-[#f1f1f1] hover:bg-[#002ce3] ease-in-out duration-100 hover:text-white font-semibold rounded-lg text-sm">L</button>
                                        <button wxp-selection="true" onClick={(e) => { setSelectedSize(e.target.getAttribute('id')) }} id="XL" className="p-3 bg-[#f1f1f1] hover:bg-[#002ce3] ease-in-out duration-100 hover:text-white font-semibold rounded-lg text-sm">XL</button>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 mt-3">
                                    <h1 className="text-lg font-bold text-[#1d1f21]">Choose a color</h1>
                                    <div className="flex gap-3">
                                        <button wxp-selection-color="true" onClick={(e) => { setSelectedColor(e.target.getAttribute('id')) }} id="black" className="p-4 bg-[#000] hover:outline-double outline-offset-1 shadow-xl  ease-in-out duration-100 rounded-full text-sm"></button>
                                        <button wxp-selection-color="true" onClick={(e) => { setSelectedColor(e.target.getAttribute('id')) }} id="red" className="p-4 bg-[red] hover:outline-double shadow-xl outline-offset-1 ease-in-out duration-100 rounded-full text-sm"></button>
                                        <button wxp-selection-color="true" onClick={(e) => { setSelectedColor(e.target.getAttribute('id')) }} id="gray" className="p-4 bg-gray-400 hover:outline-double outline-offset-1 shadow-xl  ease-in-out duration-100 rounded-full text-sm"></button>

                                    </div>
                                </div>
                                <div className="flex gap-3 mt-3 w-full items-center">
                                    <Select size="large" options={addItemsCountArr} defaultValue="1" />
                                    <div className="w-full flex gap-3">
                                        <button className="flex items-center gap-2 text-lg bg-[#002ce3] text-white px-8 py-3 rounded-lg hover:shadow-xl tansition ease-in-out duration-300">
                                            <AiOutlineShoppingCart />
                                            Add to cart
                                        </button>
                                        <Tooltip title={isFavorite ? "Remove from favourites" : "Add to favourites"}>
                                            <button onClick={() => { setIsFavorite(!isFavorite) }} className="flex items-center gap-2 text-lg bg-[#eb6127] text-white px-4 py-3 rounded-lg hover:shadow-xl tansition ease-in-out duration-300">
                                                {isFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" my-20">
                        <div className="lg:m-16 m-4 lg:mx-24 flex flex-col gap-8">
                            <div className="text-2xl font-bold text-center">You Might Also Like</div>
                            <div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                                    {products.slice(0, 4).map((product, index) => {
                                        return (
                                            <ProductCard url={product.id} image={product.image} name={product.name} price={product.price} discount={product.discount ? product.discount : 'NEW'} key={index} />
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="uppercase font-bold text-2xl font-manrope">New Arrivals</div>
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                                    {products.slice(5, 9).map((product, index) => {
                                        return (
                                            <ProductCard url={product.id} image={product.image} name={product.name} price={product.price} discount={product.discount ? product.discount : 'NEW'} key={index} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ThreePromises />
            </>}

            <Footer />
        </>
    )
}
export default ProductPreview;